<template>
  <v-app>
    <div style="position:fixed; background-color:red; bottom: 0px; left: 0px;">
    </div>
    <div v-if="!light_on" class=" pa-2 flex-column d-flex justify-space-around" style="height:80%; position:absolute; color:rgb(204 255 7); background-color:black; width: 100%">
    <typical
      class="text"
      style="text-align:center"
      :steps="['Moin Mo!', 1000]"
    ></typical>
    <typical
      class="text"
      style="text-align:center"
      :steps="['', 1100, 'Alles Gute zum Geburtstag!', 1500]"
    ></typical>
    <typical
      class="text"
      style="text-align:center"
      :steps="['', 2800, 'Das ist ein Gutschein für eine Lampe, die du mit mir in Berlin bauen kannst!', 2500]"
    ></typical>
    <typical
      class="text"
      style="text-align:center"
      :steps="['', 8000, 'Eddien', 2500]"
    ></typical>
    <div class="d-flex justify-center">
      <v-btn class="mt-4" fab color="rgb(207, 163, 80)" v-if="!light_on && text_finished" @click="light_on = !light_on">
        <v-icon x-large>
          lightbulb
        </v-icon>
      </v-btn>
    </div>

    </div>
    <v-container fluid style="background-color:black; height: 100%;" class="ma-0 pa-0 d-flex justify-center align-center">
      <v-img
        v-if="light_on"
        lazy-src="https://picsum.photos/id/11/10/6"
        height="100%"
        src="@/assets/vortex_4_1800x.gif"
      >
    <v-btn class="ml-2 mt-2" fab color="rgb(207, 163, 80)" v-if="light_on" @click="reset()">
      <v-icon x-large>
        replay
      </v-icon>
    </v-btn>     
      </v-img>
    </v-container>
  </v-app>
</template>

<script>
import typical from 'vue-typical'

export default {
  components: {
    typical
  },
  name: 'App',
  data () {
    return {
      light_on: false,
      text_finished: false,
    }
  },
  methods: {
    reset () {
      this.light_on = false
      const self = this
      this.text_finished = false
      setTimeout(function () {
        self.text_finished = true
      }, 10000)      
    }
  },
  mounted () {
    const self = this
    setTimeout(function () {
      self.text_finished = true
    }, 10000)
  }
}
</script>

<style>
  .text {
    color:rgb(218, 149, 21);
    font-family: 'Staatliches', cursive;
    font-size: 2em;
  }
</style>
